<template>
  <div class="flex justify-start bg-white rounded-md p-2">
    <div class="text-[#354357] font-bold my-auto" >
      <h2 class="text-lg">
        {{props.title}}
      </h2>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Header',
  props: {
    title: {
      type: String,
      default: 'Listado de RIPS'
    }
  },
  setup (props) {
    return {
      props
    }
  }
}
</script>
<style scoped></style>
